html {
    -webkit-tap-highlight-color:rgba(0,0,0,0)
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *::before, *::after {
    box-sizing: inherit
}

a {
    text-decoration: none;
    color: #0073D1;
    cursor: pointer;
}

html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0
}

h1, h2, h3, h4, h5, h6 {
    font-size: 100%;
    font-weight: normal
}

img, embed, iframe, object, video {
    height: auto;
    max-width: 100%;
}

iframe {
    border: none;
    background-color: #FFF
}

.title-1 {
    font-size: 34px;
    font-weight: bold;
    letter-spacing: -0.41;
}

.title-2 {
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    color: #757575;
}

.title-3 {
    font-size: 1rem;
    color: #757575
}

.title-4 {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.5rem;
}

.title-5 {
    font-size: 1.6rem;
    font-weight: 600
}

.subtitle-1 {
    font-size: 1.25rem;
    color:#3a3a3a;
    font-weight: 400;
    line-height: 1.6rem; 
}

.subtitle-2 {
    font-size: 1rem;
    color:#757575;
    font-weight: 400;
}

.subtitle-3 {
    font-size: 1rem;
    font-weight: bold;
}

.subtitle-5 {
    color:#757575;
    margin-top: 6px
}

.placeholder-text {
    color: #b3b3b3;
}

.form-label {
    color: #3a3a3a;
    display: block;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 8px;
}

.form-input {
    background-color: white;
    border:solid 2px #eaeaea;
    color: black;
    box-shadow: none;
    max-width: 100%;
    width: 100%;
    font-size: 1rem;
    padding: 5px 9px;
    line-height: 2rem;
    margin-bottom: 12px;
    border-style:solid;
    -webkit-appearance: none;
}

.form-input:focus {
    outline: none;
    border: solid 2px #b3b3b3
}

.form-help {
    display: block;
    margin-top: 0.5rem;
    color: #E8021D;
}

.pile-count {
    color: #757575;
    font-size: 0.75rem;
    margin-left: 8px;
}

.link-button {
    display: block;
    font-weight: bold;
    width: 100%;
    margin: 20px 0;
    text-align: center
}

.link-button-inactive {
    display: block;
    font-weight: bold;
    width: 100%;
    margin: 20px 0;
    text-align: center;
    color: #757575;
}

.link-button-no-width {
    display: block;
    font-weight: bold;
    margin: 20px 0;
}

.icon-button {
    color:#3a3a3a;
    background-color: #f8f8f8;
    border: solid 1px #eaeaea;
    border-radius: 50%;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    display: inline-flex;
    box-shadow: none;
    height: 44px;
    width: 44px;
    position: relative;
    flex-shrink: 0
}

.icon-button:active {
    background-color: #b3b3b3
}

.icon-button svg {
    width: 24px;
    height: 24px;
}

.title-button {
    color:#3a3a3a;
    background-color: #f8f8f8;
    border: solid 1px #eaeaea;
    border-radius: 8px;
    cursor: pointer;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    display: inline-flex;
    box-shadow: none;
    height: 44px;
    /* position: relative; */
    padding: 0 8px;
}

.flipped-on {
    border: solid 1px #11d148;
    color: #11d148
}

.horizontal-margin {
    margin:0 8px
}

.icon-text-button {
    color:#3a3a3a;
    background-color: white;
    border: solid 1px #eaeaea;
    border-radius: 14px;
    cursor: pointer;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    display: inline-flex;
    box-shadow: none;
    height: 44px;
    position: relative;
    padding: 0 8px;
}

.icon-text-button:active {
    background-color: #b3b3b3
}

.icon-text-button svg {
    width: 24px;
    height: 24px;
}

.primary-button {
    color:black;
    background-color: #11d148;
    border: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    display: inline-flex;
    border-radius: 10px;
    box-shadow: none;
    height: 55px;
    width: 100%;
    font-size: 1rem;
    font-weight: 600;
    position: relative;
    margin-top: 12px;
}

.pill-button {
    background-color: #0073D1;
    color: #FFF;
    font-weight: bold;
    border-radius: 24px;
    text-align: center;
    width: 160px;
    padding:10px 0;
}

.settings-body-container .pill-button {
    margin:40px 0 12px
}

.flex-group {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.flex-group-vertical {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.cutoff-able-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.two-line-title {
    text-align: center
}

.circle {
    border-radius: 50%;
    flex-shrink: 0;
}

.small {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    font-size: 12px
}

.medium {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    font-size: 24px;
}

.large {
    width: 44px;
    height: 44px;
    margin-right: 8px;
    font-size: 26px
}

.inbox-header-button-group {
    flex-shrink: 0;
    margin-left: 10px; 
    white-space: nowrap;
}

.inbox-header-button-group p {
    margin: -6px 10px 0 0;
    color:#757575
}

.unread {
    font-weight: bold
}

.emphasized {
    font-weight: bold
}

.selected {
    background-color: #eaeaea !important
}

a.selected {
    text-decoration: underline
}

.hover-buttons {
    position: absolute;
    right:0;
    height: 100%;
    background-color: #f8f8f8;
    display: none;
    align-items: center;
    padding: 0 6px 0 14px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    border:solid 1px #eaeaea;
}

.hover-buttons a {
    height: 30px;
    width: 30px;
}

div.inbox-row:hover .hover-buttons {
    display: flex;
}

.placeholder-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eaeaea;
    color: #757575;
}

.show-when-mobile {
    display: none !important
}

.info-row {
    display: flex;
    padding: 6px 0;
    cursor: pointer;
    align-items: center;
    min-height: 24px;
}

.info-row-subtitle {
    color: #757575;
}

.info-row-title {
    font-weight: 600;
}

.subscriptions-toggle-button {
    text-transform: lowercase;
    letter-spacing: 0;
    cursor: pointer;
    text-decoration: underline
}

#app-sidebar a {
    color: #3a3a3a
}

.sidebar-content {
    padding: 24px;    
}

.folder-filter-container {
    height:31px;
    width: 100%;
    border-bottom: solid 1px #3a3a3a;
    /* border-radius: 10px; */
    display:flex;
    align-items: center;
    justify-content: space-evenly;
    overflow: hidden;
    font-weight: bold;
    margin:16px 0 12px;
}

.folder-filter-container a {
    height:31px;
    width: 33.34%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

#app-sidebar a.filter-selected {
    background-color: #3a3a3a;
    color: #FFF;
}

#save-confirm {
    position: absolute;
    top: 144px;
    right: 30px;
}

.empty-state-container {
    margin-top:calc(50vh - 70px - 87px)
}

.save-type-picker-container a {
    display: flex;
    margin: 24px 0 0 24px;
    align-items: center;
    font-size: 1.4em;
}

.save-type-picker-container svg {
    margin-right: 8px;
}

.hide {
    display: none !important
}

#folder-search-container {
    position: absolute;
    top: 24px;
    left: 12px;
    padding:20px;
    width: calc(80vw - 24px);
    height: 100vh;
    background-color: #FFF;
    border-radius: 4px;
    z-index: 10;
}

#folder-search-container input {
    border: none;
    font-size: 1.25rem;
    margin-bottom: 12px
}

#folder-search-container input:focus {
    outline: none
}

#folder-search-container a {
    color: #3a3a3a
}

#fixed-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    border: solid 1px #eaeaea;
    background-color: #f8f8f8;
    padding: 6px;
    color:#757575;
    font-size: .75rem;
}

.modal-background {
    z-index: 5;
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.45);
    top:0;
    left:0;
}

.modal-background a {
    position: absolute;
    top: 24px;
    right: 24px;
}

.modal-container {
    position: absolute;
    border-radius: 12px;
    z-index: 6;
    background-color: #fff;
    top:-460px;
    right:0;
    left:0;
    transition: 0.4s;
    width: 360px;
    max-width: calc(100vw - 24px);
    padding: 22px;
    margin: 0 auto;
    box-sizing: border-box;
}

.modal-container label {
    margin-bottom: 0px
}

.modal-container .form-help {
    margin-top: 20px
}

.modal-slide-down {
    top: 74px !important
}

.overlay-info-box {
    position: absolute;
    /* top: 24px;
    left: 11px; */
    border:solid 1px #eaeaea;
    padding: 24px;
    z-index: 10;
    background-color: #f8f8f8;
    max-width: 600px;
    max-height: calc(100vh - 48px);
    overflow: scroll;
}

.overlay-info-box td {
    padding: 4px
}

.overlay-info-box table {
    margin: 12px 0
}

.email-info-card {
    top:76px;
    width: 80%;
    max-width: 500px;
}

.key-symbol {
    border: solid 1px #eaeaea;
    padding: 3px;
    border-radius: 3px;
    background-color: white;    
}

.highlighted {
    background-color: #eaeaea;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    margin-left: -24px;
    padding-left: 24px;
    padding-right: 24px;
}

.highlighted span {
    color: black;
    font-weight: bold;
}

.merge-queue-item {
    margin:12px 0;
    padding: 12px;
    border: solid 1px #eaeaea;
    position: relative;
}

.merge-queue-from {
    margin:12px 0
}

.merge-queue-delete {
    position: absolute;
    top:12px;
    right:12px
}

/* // STRUCTURE // */
.inbox-container {
    padding: 0;
    display: flex;
    flex-direction: column;
    position: relative;
}

.login-container {
    width: 360px;
    max-width: 100vw;
    padding: 28px 16px 0;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
}

.app-container {
    display: flex;
    padding: 0;
    margin: 0;
}

#app-sidebar {
    max-width: 100vw;
    position: relative;
    border: solid 1px #eaeaea;
    background-color: #f8f8f8;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    display: block
}

#app-main-view {
    padding: 0;
    flex: none;
    position: relative;
    display: block
}

.loading-screen-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: calc(50vh - 9px);
}

.inbox-body-container {
    -webkit-overflow-scrolling: touch;
    padding: 0;
}

.inbox-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0 0 0;
    padding: 0 24px;
    box-sizing: border-box;
    height: 51px;
    width: 80%;
    background-color: #FFF;
}

.email-iframe {
    height: calc(100vh - 97px);
}

.video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.video-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.settings-body-container .title-2 {
    margin: 30px 0 8px;
}

.settings-body-container li {
    list-style: none;
    margin-bottom: 8px;
}

.settings-body-container a {
    display: block;
    margin-top: 8px;
}

.settings-body-container select {
    margin-bottom: 12px;
}

.settings-body-container label {
    margin-top: 20px;
}

@media only screen and (min-width: 769px) {
    .settings-body-container {
        margin: 87px 24px;
        max-width: 400px;
        box-sizing: border-box;
    }

    .video-thumbnail {
        height: 80px;
        margin-right:12px;
    }

    #app-sidebar {
        height: 100vh;
        overflow-y: auto;
        width: 20%;
    }

    #app-main-view {
        width: 80%;
    }

    .inbox-header-container {
        position: fixed;
        z-index: 2;
    }

    .inbox-body-container {
        margin: 87px 0 0 0;
        height: calc(100vh - 87px);
        overflow-y: scroll;
        padding: 0 24px;
    }

    .inbox-row {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        border-bottom: solid 1px #eaeaea;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 9px 12px;
        position: relative
    }

    .inbox-row-text {
        flex-shrink: 2;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #b3b3b3
    }

    .info-row-title {
        font-size: 1.1em;
    }

    .info-row-subtitle {
        font-size: .9em;
    }

    .unread-indicator {
        position: absolute;
        left:0;
        width:8px;
        height:8px;
        border-radius: 50%;
        background-color: #11d148
    }

    .overlay-info-box {
        top: 24px;
        left: 11px
    }

    @media (prefers-color-scheme: dark) {
        .inbox-row {
            border-bottom: solid 1px #3a3a3a;
        }
    }
}
@media only screen and (max-width: 768px) {
    html {
        overflow: auto !important
    }

    .loading-screen-container {
        margin-top: calc(50vh - 9px - 97px);
    }

    .large {
        width: 32px;
        height: 32px;
        font-size: 16px
    }

    .icon-button {
        border:none;
    }

    .settings-body-container {
        margin: 0 18px calc(49px + env(safe-area-inset-bottom) + 12px);
        box-sizing: border-box;
    }

    #app-sidebar a.icon-button {
        background-color: #fff
    }

    .title-button {
        border:none;
        background-color: #fff
    }

    #app-sidebar {
        width: 100vw;
        position: fixed;
        top:0;
        left:-101vw;
        z-index: 10;
        transition: 0.3s;
        overflow-x: hidden;
        height: 100%;
        font-size: 1.25rem;
        padding-top: 24px;
        padding-bottom: calc(24px + env(safe-area-inset-bottom));
        box-sizing: border-box;
    }

    #app-main-view {
        width:100%;
    }

    .hide-when-mobile {
        display: none !important
    }

    .show-when-mobile {
        display: flex !important
    }

    .inbox-body-container {
        padding-bottom: calc(49px + env(safe-area-inset-bottom));
    }

    .mobile-nav {
        background-color:#FFF;
        color:#3a3a3a;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        height:calc(49px + env(safe-area-inset-bottom));
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 5;
        border-top: solid 1px #eaeaea;
        background-color: #f8f8f8;
        padding-bottom: env(safe-area-inset-bottom)
    }

    .mobile-nav a {
        width:50%;
        height: 49px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #b3b3b3;
    }

    .mobile-nav a.active {
        color:#000;
    }

    .mobile-nav a:first-child {
        border-right: solid 1px #eaeaea
    }

    .slide-out-when-mobile {
        left: 0px !important
    }

    .inbox-header-container {
        margin: 0;
        width: 100%;
        padding:0 18px;
        height:97px;
    }

    .inbox-header-button-group {
        margin-left: 0
    }

    .active-issue {
        position: fixed;
        z-index: 2;
        height: 51px;
        border-bottom: solid 1px #eaeaea;
    }

    .inbox-row {
        padding: 6px 18px 18px;
        margin: 0 12px 12px;
        border: solid 1px #eaeaea;
        border-radius: 10px;
        box-shadow: 0 1px 4px 0 rgba(32,33,36,.28);
        position: relative
    }

    .inbox-row-video {
        padding:0 0 18px;
        max-width: 420px;
    }

    .video-thumbnail {
        height:194px;
        object-fit: cover;
        width: 100%;
    }

    .video-thumbnail-container {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        overflow: hidden;
        height: 194px;
    }

    .video-card-info-container {
        padding: 6px 18px 0;
    }

    .unread-indicator {
        right:0px;
        bottom:0px;
        border-bottom-right-radius: 10px;
        position: absolute;
        width: 0; 
        height: 0; 
        border-left: 9px solid transparent;
        border-top: 9px solid transparent;
        border-bottom: 9px solid #11d148;
        border-right: 9px solid #11d148;
    }

    div.inbox-row:hover .hover-buttons {
        display: none;
    }

    .mobile-inbox-cards-info-row {
        margin-bottom: 8px;
        font-size: 1.25em;
    }

    .overlay-info-box {
        top: 64px;
        right: 12px
    }

    .folder-filter-container {
        height:40px;
    }
    
    .folder-filter-container a {
        height:40px;
    }

    #settings {
        margin-top: 27px
    }

    .info-row {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .info-row-subtitle {
        font-size: .8em;
        display: block;
        margin-top: -1px;
    }

    .email-iframe {
        margin-top: 51px;
        height: calc(100vh - 51px - 49px);
    }

    .video-container {
        margin-top: 51px;
    }

    .mobile-email-footer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        box-sizing: border-box;
        position: fixed;
        z-index: 2;
        width: 100%;
        bottom: 0;
        height: calc(49px + env(safe-area-inset-bottom));
        padding:0 12px env(safe-area-inset-bottom);
        border-top: solid 1px #eaeaea;
        background-color: #f8f8f8
    }

    .mobile-change-issue-button {
        height: 100%;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        color: #3a3a3a;
        background-color: #f8f8f8
    }

    .mobile-change-issue-button:active {
        opacity: 0.6;
    }

    .mobile-mark-as-read-button {
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.45);
        background-color: #eaeaea;
        border-radius: 50%;
        margin-top: -19px;
        justify-content: center;
        align-items: center;
        justify-self: center;
        height: 49px;
        width: 49px
    }

    .mobile-mark-as-unread-button {
        justify-content: center;
        align-items: center;
        align-self: center;
        height: 49px;
        width: 49px;
        color:#3a3a3a;
        cursor: pointer;
        display: inline-flex;
        flex-shrink: 0
    }

    .empty-state-container {
        margin-top:calc(50vh - 70px - 97px)
    }

    .title-2 {
        font-size: 1rem;
    }

    .title-3 {
        font-size: 0.8rem;
    }

    .subtitle-3 {
        font-size: 0.8rem;
    }

    .icon-button svg {
        width: 28px;
        height: 28px;
    }

    .icon-button div.circle {
        width: 28px;
        height: 28px
    }

    .icon-button img.circle {
        width: 28px;
        height: 28px
    }

    /* #app-sidebar div.circle {
        width: 28px;
        height: 28px
    } */

    /* #app-sidebar img.circle {
        width: 28px;
        height: 28px
    } */

    #app-sidebar svg {
        width: 28px;
        height: 28px
    }

    .modal-background a {
        position: absolute;
        top: 8px;
        right: 12px;
    }

    @media (prefers-color-scheme: dark) {
        .inbox-row {
            border: solid 1px #3a3a3a;
            background-color: #1f1f1f;
            /* box-shadow: 0 1px 4px 0 rgba(32,33,36,.28); */
        }
    }

}
/* Dark mode */
@media (prefers-color-scheme: dark) {

    /*
        #1f1f1f <=> #f8f8f8
        #000 <=> #FFF
        #757575 => #eaeaea
        #3a3a3a => #eaeaea
        #eaeaea => #3a3a3a
        #b3b3b3 stays
    */

    body {
        background-color: #000;
        color: #FFF;
    }

    a {
        color: #4DAFFF;
    }

    .pill-button {
        background-color: #4DAFFF;
    }
    
    .title-2 {
        color: #eaeaea;
    }
    
    .title-3 {
        color: #eaeaea
    }
    
    .subtitle-1 {
        color:#eaeaea;
    }
    
    .subtitle-2 {
        color:#eaeaea;
    }

    .subtitle-5 {
        color:#eaeaea;
    }
    
    .placeholder-text {
        color: #b3b3b3;
    }
    
    .form-label {
        color: #eaeaea;
    }
    
    .form-input {
        background-color: white;
        border:solid 2px #3a3a3a;
        color: black;
    }
    
    .form-input:focus {
        border: solid 2px #b3b3b3
    }
    
    .pile-count {
        color: #eaeaea;
    }
    
    .link-button-inactive {
        color: #eaeaea;
    }
    
    .icon-button {
        color:#eaeaea;
        background-color: #1f1f1f;
        border: solid 1px #3a3a3a;
    }
    
    .icon-button:active {
        background-color: #b3b3b3
    }

    #app-sidebar a.icon-button {
        background-color: #000
    }
    
    .title-button {
        color:#eaeaea;
        background-color: #1f1f1f;
        border: solid 1px #3a3a3a;
    }
    
    .icon-text-button {
        color:#eaeaea;
        background-color: #000;
        border: solid 1px #3a3a3a;
    }
    
    .icon-text-button:active {
        background-color: #b3b3b3
    }

    .inbox-header-button-group p {
        color:#eaeaea
    }
    
    .selected {
        background-color: #3a3a3a !important
    }
    
    .hover-buttons {
        background-color: #1f1f1f;
        border:solid 1px #3a3a3a;
    }
   
    .placeholder-logo {
        background-color: #3a3a3a;
        color: #eaeaea;
    }
    
    #app-sidebar a {
        color: #eaeaea
    }
    
    #folder-search-container {
        background-color: #000;
    }
    
    #folder-search-container a {
        color: #eaeaea
    }
    
    #fixed-footer {
        border: solid 1px #33aa33;
        background-color: #1f1f1f;
        color:#eaeaea;
    }
    
    .modal-background {
        background-color: rgba(0,0,0,0.45);
    }
    
    .modal-container {
        background-color: #000;
    }
    
    .overlay-info-box {
        border:solid 1px #3a3a3a;
        background-color: #1f1f1f;
    }
    
    .key-symbol {
        border: solid 1px #3a3a3a;
        background-color: #000;    
    }
    
    .highlighted {
        background-color: #3a3a3a;
    }
    
    .highlighted span {
        color: #FFF;
    }
    
    #app-sidebar {
        border: solid 1px #3a3a3a;
        background-color: #1f1f1f;
    }

    .mobile-nav {
        background-color:#000;
        color:#eaeaea;
        border-top: solid 1px #3a3a3a;
        background-color: #1f1f1f;
    }

    .mobile-nav a {
        color: #757575;
    }

    .mobile-nav a.active {
        color: #fff;
    }

    .mobile-nav a:first-child {
        border-right: solid 1px #3a3a3a
    }

    .mobile-email-footer {
        border-top: solid 1px #3a3a3a;
        background-color: #1f1f1f
    }

    .mobile-change-issue-button {
        color: #eaeaea;
        background-color: #1f1f1f
    }

    .mobile-mark-as-read-button {
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.45);
        background-color: #3a3a3a;
    }

    .mobile-mark-as-unread-button {
        color:#eaeaea;
    }

    .inbox-header-container {
        background-color: #000;
    }

    .folder-filter-container {
        border: solid 2px #eaeaea;
    }
    
    #app-sidebar a.filter-selected {
        background-color: #eaeaea;
        color: #000;
    }

    .active-issue {
        border-bottom: solid 1px #3a3a3a;
    }

    .info-row-subtitle {
        color: #b3b3b3;
    }
}

